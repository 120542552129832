<template>
  <div>
    <form-modal
      title="Edição da Base de Análise"
      :cols="colsBaseAnalise"
      :maxWidth="500"
      :opened.sync="baseOpened"
      :opts="opts"
      :value.sync="selectedItem"
      @cancel="selectedItem = {}"
      @save="onSave"
    ></form-modal>
    <form-modal
      title="Edição de Valores do Item"
      :cols="colsItem"
      :maxWidth="500"
      :opened.sync="itemOpened"
      :opts="opts"
      :value.sync="selectedItem"
      @cancel="selectedItem = {}"
      @save="onSave"
    ></form-modal>
    <form-modal
      title="Edição de Produto e Modelo"
      maxWidth="500"
      :cols="colsProdutoModelo"
      :opened.sync="produtoOpened"
      :opts="opts"
      :value.sync="selectedItem"
      @cancel="selectedItem = {}"
      @save="onSave"
    ></form-modal>
    <master-detail
      descriptionProperty="chave_nfe"
      formTitle="Nota Fiscal/Item"
      resourceId="itemId"
      :formWidth="1400"
      :cols="cols"
      :contextOptions="options"
      :customResource="customResource"
      :opts="opts"
      :canDelete="false"
      :canEdit="false"
      :expandSubItem="true"
      :hasNewButton="false"
      :hasOneMonthFilter="true"
    ></master-detail>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  components: {
    "form-modal": () => import("@/components/form-modal.vue"),
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    clientId: function () {
      return this.getClient().clientId;
    },
    cols: function () {
      return [
        /* NFE */
        {
          name: "NFE",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "num_doc",
          name: "Nº Doc",
          type: this.$fieldTypes.TEXT,
          editable: false,
          filterable: true,
          colSize: 4,
        },
        {
          key: "chave_nfe",
          name: "Chave Nf-e",
          type: this.$fieldTypes.TEXT,
          colSize: 7,
        },
        {
          key: "data_emissao",
          name: "Dt Emissão",
          type: this.$fieldTypes.DATE,
          editable: false,
          filterable: true,
          colSize: 3,
        },
        {
          key: "serie",
          name: "Série",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 2,
        },
        /* Fim NFE */
        /* Item NFE */
        {
          name: "Item NFE",
          type: this.$fieldTypes.SUBTITLE,
        },

        {
          key: "cfop",
          name: "CFOP",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "cfops", key: "cfop", name: "cfop" },
          filterable: true,
          editable: false,
          colSize: 2,
        },
        {
          key: "desc_cfop",
          name: "Descrição CFOP",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 6,
        },
        {
          key: "ncm",
          name: "NCM",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 2,
        },
        {
          key: "codigo",
          name: "Cód. Item",
          type: this.$fieldTypes.TEXT,
          editable: false,
          filterable: true,
          colSize: 2,
        },
        {
          key: "descr_compl",
          name: "Desc. Complementar",
          type: this.$fieldTypes.TEXT,
          editable: false,
          filterable: true,
          colSize: 8,
        },
        {
          key: "quantidade",
          name: "Quantidade",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 2,
        },
        {
          key: "vl_item",
          name: "Valor Item",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 2,
        },
        {
          key: "vl_frete",
          name: "Valor Frete",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 2,
        },
        {
          key: "vl_seg",
          name: "Valor Seg.",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 2,
        },
        {
          key: "vl_outros",
          name: "Valor Outros",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 2,
        },
        {
          key: "vl_desc",
          name: "Valor Desc",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 2,
        },
        /* Fim Item NFE */
        /* ICMS */
        {
          name: "ICMS",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "cst_icms",
          name: "CST ICMS",
          type: this.$fieldTypes.TEXT,
          hideInTable: !this.visibilidade.VL_ICMS,
          highlighted: true,
          editable: false,
          colSize: 2,
        },
        {
          key: "vl_bc_icms",
          name: "Valor BC ICMS",
          type: this.$fieldTypes.MONEY,
          hideInTable: !this.visibilidade.VL_ICMS,
          highlighted: true,
          nowrap: true,
          editable: false,
          colSize: 2,
        },
        {
          key: "aliq_icms",
          name: "Aliq. ICMS",
          type: this.$fieldTypes.TEXT,
          hideInTable: !this.visibilidade.VL_ICMS,
          highlighted: true,
          editable: false,
          colSize: 2,
        },
        {
          key: "vl_icms",
          name: "Valor ICMS",
          type: this.$fieldTypes.MONEY,
          highlighted: this.visibilidade.VL_ICMS,
          nowrap: true,
          editable: false,
          filterable: true,
          colSize: 2,
          icon: {
            text: `mdi-unfold-${
              this.visibilidade.VL_ICMS ? "less" : "more"
            }-vertical`,
            style: `background-color: ${
              this.isClient ? "#6BB4BA" : "#81B4EA"
            }; border-radius: 50%; color: #000; padding: 2px;`,
            click: () => {
              this.visibilidade.VL_ICMS = !this.visibilidade.VL_ICMS;
            },
          },
        },
        {
          key: "vl_icms_op",
          name: "Valor ICMS OP",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 2,
          hideInTable: !this.visibilidade.VL_ICMS,
          highlighted: true,
        },
        {
          key: "p_dif",
          name: "P DIF",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 2,
          hideInTable: !this.visibilidade.VL_ICMS,
          highlighted: true,
        },
        {
          key: "vl_icms_dif",
          name: "Valor ICMS DIF",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 2,
          hideInTable: !this.visibilidade.VL_ICMS,
          highlighted: true,
        },
        {
          key: "vl_bc_icms_st",
          name: "Valor BC ICMS ST",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 2,
        },
        {
          key: "aliq_st",
          name: "Aliq. ST",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 2,
        },
        {
          key: "vl_icms_st",
          name: "Valor ICMS ST",
          type: this.$fieldTypes.MONEY,
          highlighted: this.visibilidade.VL_ICMS_ST,
          nowrap: true,
          editable: false,
          colSize: 2,
          icon: {
            text: `mdi-unfold-${
              this.visibilidade.VL_ICMS_ST ? "less" : "more"
            }-vertical`,
            style: `background-color: ${
              this.isClient ? "#6BB4BA" : "#81B4EA"
            }; border-radius: 50%; color: #000; padding: 2px;`,
            click: () => {
              this.visibilidade.VL_ICMS_ST = !this.visibilidade.VL_ICMS_ST;
            },
          },
        },
        {
          key: "fcp_st",
          name: "FCP ST",
          type: this.$fieldTypes.TEXT,
          hideInTable: !this.visibilidade.VL_ICMS_ST,
          highlighted: true,
          editable: false,
          colSize: 2,
        },
        {
          key: "deson_icms",
          name: "Deson. ICMS",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 2,
          hideInTable: !this.visibilidade.VL_ICMS_ST,
          highlighted: true,
        },
        {
          key: "difal_dest",
          name: "Difal Dest.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
          hideInTable: !this.visibilidade.VL_ICMS_ST,
          highlighted: true,
        },
        {
          key: "difal_remet",
          name: "Difal Remet.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
          hideInTable: !this.visibilidade.VL_ICMS_ST,
          highlighted: true,
        },
        {
          key: "difal_fcp",
          name: "Difal FCP",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
          hideInTable: !this.visibilidade.VL_ICMS_ST,
          highlighted: true,
        },
        /* FIM ICMS */
        /* IPI */
        {
          name: "IPI",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "ex_tipi",
          name: "EX TIPI",
          type: this.$fieldTypes.TEXT,
          hideInTable: !this.visibilidade.VL_IPI,
          highlighted: true,
          editable: false,
          colSize: 4,
        },
        {
          key: "cst_ipi",
          name: "CST IPI",
          type: this.$fieldTypes.TEXT,
          hideInTable: !this.visibilidade.VL_IPI,
          highlighted: true,
          editable: false,
          colSize: 4,
        },
        {
          key: "vl_bc_ipi",
          name: "Valor BC IPI",
          type: this.$fieldTypes.MONEY,
          hideInTable: !this.visibilidade.VL_IPI,
          highlighted: true,
          nowrap: true,
          editable: false,
          colSize: 2,
        },
        {
          key: "aliq_ipi",
          name: "Aliq. IPI",
          type: this.$fieldTypes.TEXT,
          hideInTable: !this.visibilidade.VL_IPI,
          highlighted: true,
          editable: false,
          colSize: 4,
        },
        {
          key: "vl_ipi",
          name: "Valor IPI",
          type: this.$fieldTypes.MONEY,
          highlighted: this.visibilidade.VL_IPI,
          nowrap: true,
          editable: false,
          filterable: true,
          colSize: 2,
          icon: {
            text: `mdi-unfold-${
              this.visibilidade.VL_IPI ? "less" : "more"
            }-vertical`,
            style: `background-color: ${
              this.isClient ? "#6BB4BA" : "#81B4EA"
            }; border-radius: 50%; color: #000; padding: 2px;`,
            click: () => {
              this.visibilidade.VL_IPI = !this.visibilidade.VL_IPI;
            },
          },
        },
        {
          key: "cod_enq",
          name: "Cód. Enq.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 6,
          hideInTable: !this.visibilidade.VL_PIS,
          highlighted: true,
        },
        /* FIM IPI */
        /* PIS COFINS */
        {
          name: "PIS COFINS",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "cst_pis_cofins",
          name: "CST PIS COFINS",
          type: this.$fieldTypes.TEXT,
          hideInTable: !this.visibilidade.VL_PIS,
          highlighted: true,
          editable: false,
          colSize: 4,
        },
        {
          key: "vl_bc_pis_cofins",
          name: "Valor BC PIS COFINS",
          type: this.$fieldTypes.MONEY,
          hideInTable: !this.visibilidade.VL_PIS,
          highlighted: true,
          nowrap: true,
          editable: false,
          colSize: 2,
        },
        {
          key: "aliq_pis",
          name: "Aliq. PIS",
          type: this.$fieldTypes.TEXT,
          hideInTable: !this.visibilidade.VL_PIS,
          highlighted: true,
          editable: false,
          colSize: 4,
        },
        {
          key: "vl_pis",
          name: "Valor PIS",
          type: this.$fieldTypes.MONEY,
          highlighted: this.visibilidade.VL_PIS,
          nowrap: true,
          editable: false,
          colSize: 2,
          icon: {
            text: `mdi-unfold-${
              this.visibilidade.VL_PIS ? "less" : "more"
            }-vertical`,
            style: `background-color: ${
              this.isClient ? "#6BB4BA" : "#81B4EA"
            }; border-radius: 50%; color: #000; padding: 2px;`,
            click: () => {
              this.visibilidade.VL_PIS = !this.visibilidade.VL_PIS;
            },
          },
        },
        {
          key: "aliq_cofins",
          name: "Aliq. COFINS",
          type: this.$fieldTypes.TEXT,
          hideInTable: !this.visibilidade.VL_COFINS,
          highlighted: true,
          editable: false,
          colSize: 4,
        },
        {
          key: "vl_cofins",
          name: "Valor COFINS",
          type: this.$fieldTypes.MONEY,
          highlighted: this.visibilidade.VL_COFINS,
          nowrap: true,
          editable: false,
          colSize: 2,
          icon: {
            text: `mdi-unfold-${
              this.visibilidade.VL_COFINS ? "less" : "more"
            }-vertical`,
            style: `background-color: ${
              this.isClient ? "#6BB4BA" : "#81B4EA"
            }; border-radius: 50%; color: #000; padding: 2px;`,
            click: () => {
              this.visibilidade.VL_COFINS = !this.visibilidade.VL_COFINS;
            },
          },
        },
        /* FIM PIS COFINS */
        /* OUTRAS INFORMAÇÕES */
        {
          name: "OUTRAS INFORMAÇÕES",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "nome_emit",
          name: "Nome Emit.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          filterable: true,
          colSize: 8,
        },
        {
          key: "cnpj_emit",
          name: "CNPJ Emit.",
          type: this.$fieldTypes.CNPJ,
          editable: false,
          colSize: 4,
        },
        {
          key: "nome_dest",
          name: "Nome Dest.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          filterable: true,
          colSize: 8,
        },
        {
          key: "cnpj_dest",
          name: "CNPJ Dest.",
          type: this.$fieldTypes.CNPJ,
          editable: false,
          colSize: 4,
        },
        {
          key: "uf_dest",
          name: "UF Dest.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 2,
          highlighted: this.visibilidade.UF_DEST,
          icon: {
            text: `mdi-unfold-${
              this.visibilidade.UF_DEST ? "less" : "more"
            }-vertical`,
            style: `background-color: ${
              this.isClient ? "#6BB4BA" : "#81B4EA"
            }; border-radius: 50%; color: #000; padding: 2px;`,
            click: () => {
              this.visibilidade.UF_DEST = !this.visibilidade.UF_DEST;
            },
          },
        },
        {
          key: "suframa_dest",
          name: "SUFRAMA Dest.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          filterable: true,
          colSize: 2,
          hideInTable: !this.visibilidade.UF_DEST,
          highlighted: true,
        },
        {
          key: "chave_ref",
          name: "Chave Ref.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
          hideInTable: !this.visibilidade.UF_DEST,
          highlighted: true,
        },
        {
          key: "valor_total_nf",
          name: "Valor Total NF",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 4,
          hideInTable: !this.visibilidade.UF_DEST,
          highlighted: true,
        },
        {
          key: "inf_adic_prod",
          name: "Inf. Adic. Prod.",
          type: this.$fieldTypes.TEXTAREA,
          editable: false,
          hideInTable: !this.visibilidade.UF_DEST,
          highlighted: true,
        },
        {
          key: "inf_comp",
          name: "Inf. Comp.",
          type: this.$fieldTypes.TEXTAREA,
          editable: false,
        },
        /* FIM OUTRAS INFORMAÇÕES */
        /* BASE DE ANÁLISE */
        {
          name: "BASE DE ANÁLISE",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "situacao_nf",
          name: "Situação NF",
          type: this.$fieldTypes.AUTOCOMPLETE,
          filterable: true,
          rel: { to: "situacao_nf", key: "name", name: "name" },
          colSize: 3,
        },
        {
          key: "sit_incentivo",
          name: "Situação Incentivo",
          type: this.$fieldTypes.AUTOCOMPLETE,
          filterable: true,
          rel: { to: "sit_incentivo", key: "id", name: "name" },
          colSize: 3,
        },
        {
          key: "ncm_incentivada",
          name: "NCM Incentivada",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "ncm_incentivada", key: "name", name: "name" },
          colSize: 3,
        },
        {
          key: "port_inf_compl",
          name: "Port. Inf. Compl.",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "port_inf_compl", key: "name", name: "name" },
          colSize: 3,
        },
        {
          key: "obs",
          name: "Obs.",
          type: this.$fieldTypes.TEXTAREA,
        },
        /* FIM BASE DE ANÁLISE */
        /* INFORMAÇÕES */
        {
          name: "INFORMAÇÕES",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "vlr_oper_integral",
          name: "Valor Oper. Integral",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 2,
        },
        {
          key: "vlr_oper",
          name: "Valor Oper.",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 2,
        },
        {
          key: "produto",
          name: "Produto",
          filterable: true,
          type: this.$fieldTypes.TEXT,
          colSize: 6,
        },
        {
          key: "modelo",
          name: "Modelo",
          filterable: true,
          type: this.$fieldTypes.TEXT,
          colSize: 6,
        },
        {
          key: "periodo",
          name: "Período",
          type: this.$fieldTypes.MONTH,
          colSize: 2,
        },
        /* FIM INFORMAÇÕES */
        /* CLASSIFICAÇÃO */
        {
          name: "CLASSIFICAÇÃO",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "cred_pres",
          name: "Cred. Pres.",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "cred_pres", key: "name", name: "name" },
          colSize: 6,
        },
        {
          key: "comp_ped",
          name: "Compromisso P&D",
          type: this.$fieldTypes.AUTOCOMPLETE,
          filterable: true,
          rel: { to: "comp_ped", key: "name", name: "name" },
          colSize: 6,
        },
      ];
    },
    colsBaseAnalise: function () {
      return [
        {
          key: "situacao_nf",
          name: "Situação NF",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "situacao_nf", key: "name", name: "name" },
          colSize: 6,
        },
        {
          key: "comp_ped",
          name: "Compromisso P&D",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "comp_ped", key: "name", name: "name" },
          colSize: 6,
        },
        {
          key: "ncm_incentivada",
          name: "NCM Incentivada",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "ncm_incentivada", key: "name", name: "name" },
          colSize: 6,
        },
        {
          key: "port_inf_compl",
          name: "Port. Inf. Compl.",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "port_inf_compl", key: "name", name: "name" },
          colSize: 6,
        },
        {
          key: "obs",
          name: "Obs.",
          type: this.$fieldTypes.TEXTAREA,
        },
      ];
    },
    colsItem: function () {
      return [
        {
          key: "vl_frete",
          name: "Valor Frete",
          type: this.$fieldTypes.MONEY,
          colSize: 4,
        },
        {
          key: "vl_seg",
          name: "Valor Seg.",
          type: this.$fieldTypes.MONEY,
          colSize: 4,
        },
        {
          key: "vl_outros",
          name: "Valor Outros",
          type: this.$fieldTypes.MONEY,
          colSize: 4,
        },
        {
          key: "vl_desc",
          name: "Valor Desc",
          type: this.$fieldTypes.MONEY,
          colSize: 4,
        },
        {
          key: "vl_icms",
          name: "Valor ICMS",
          type: this.$fieldTypes.MONEY,
          colSize: 4,
        },
        {
          key: "vl_icms_st",
          name: "Valor ICMS ST",
          type: this.$fieldTypes.MONEY,
          colSize: 4,
        },
        {
          key: "vl_ipi",
          name: "Valor IPI",
          type: this.$fieldTypes.MONEY,
          colSize: 4,
        },
        {
          key: "vl_pis",
          name: "Valor PIS",
          type: this.$fieldTypes.MONEY,
          colSize: 4,
        },
        {
          key: "vl_cofins",
          name: "Valor COFINS",
          type: this.$fieldTypes.MONEY,
          colSize: 4,
        },
      ];
    },
    colsProdutoModelo: function () {
      return [
        {
          key: "produtoId",
          name: "Produto",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "produtos", key: "id", name: "nome" },
          colSize: 12,
        },
        {
          key: "pIncentivadoId",
          name: "Modelo",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "modelos", key: "id", name: "modelo" },
          colSize: 12,
        },
      ];
    },
    customResource: function () {
      return {
        get: (param) => {
          return new Promise((resolve, reject) => {
            this.resource
              .get(param)
              .then((result) => {
                resolve(this.parseData(result));
              })
              .catch(reject);
          });
        },
      };
    },
    resourceProdutos: function () {
      return this.apiResource(`/v1/faturamento/produtos/${this.clientId}`);
    },
    resourceModelos: function () {
      return this.apiResource(
        `/v1/faturamento/produtosincentivados/${this.clientId}`
      );
    },
    resource: function () {
      return this.apiResource(
        `/v1/faturamento/conciliacao/${this.clientId}`
      );
    },
    resourceCFOPs: function () {
      return this.apiResource(`/v1/faturamento/cfop`);
    },
  },
  created: function () {
    this.resourceCFOPs.get().then((response) => {
      this.opts.cfops = response;
    });
  },
  methods: {
    onSave: function (item, closeModal) {
      this.resource.save(item, item.id).then(({ error }) => {
        if (!error) {
          closeModal();
        }
      });
    },
    parseData: function (data) {
      const result = Object
        .values(data)
        .reduce((prev, { devolucao, origem }) => {
          const data = devolucao?.map((itemDevolucao) => [itemDevolucao, cloneDeep(origem || [])]) || [];
          return [...prev, ...data];
        }, []);
      return result;
    },
  },
  data: function () {
    return {
      baseOpened: false,
      itemOpened: false,
      produtoOpened: false,
      selectedItem: {},
      visibilidade: {
        VL_ICMS: false,
        VL_ICMS_ST: false,
        VL_IPI: false,
        VL_PIS: false,
        VL_COFINS: false,
        UF_DEST: false,
      },
      opts: {
        produtos: [],
        modelos: [],
        cfops: [],
        situacao_nf: [
          { name: "NF TERCEIROS" },
          { name: "AUTORIZADA" },
          { name: "DENEGADA" },
          { name: "INUTILIZADA" },
          { name: "CANCELADA" },
        ],
        sit_incentivo: [{ name: "NÃO" }, { name: "PPB" }, { name: "TecNac" }],
        ncm_incentivada: [{ name: "SIM" }, { name: "NÃO" }],
        port_inf_compl: [
          { name: "PORTARIA CORRETA" },
          { name: "PORTARIA ERRADA" },
          { name: "SEM PORTARIA" },
          { name: "PRODUTO NÃO INCENTIVADO" },
        ],
        cred_pres: [
          { name: "SAÍDA INCENTIVADA" },
          { name: "DEVOLUÇÃO DE SAÍDA INCENTIVADA" },
          { name: "DEVOLUÇÃO DE SAÍDA NÃO INCENTIVADA" },
          { name: "OPERAÇÃO NÃO INCENTIVADA" },
        ],
        comp_ped: [
          { name: "VENDA INCENTIVADA" },
          { name: "DEVOLUÇÃO DE VENDA INCENTIVADA" },
          { name: "DEVOLUÇÃO DE VENDA NÃO INCENTIVADA" },
          { name: "VENDA ZFM" },
          { name: "DEVOLUÇÃO ZFM" },
          { name: "EXPORTAÇÃO" },
          { name: "DEVOLUÇÃO EXPORTAÇÃO" },
          { name: "OPERAÇÃO NÃO INCENTIVADA" },
        ],
      },
      options: [
        {
          name: "Editar Produto e Modelo",
          cb: (e) => {
            if (this.opts.produtos.length === 0) {
              this.resourceProdutos.get().then((response) => this.opts.produtos = response);
            }

            if (this.opts.modelos.length === 0) {
              this.resourceModelos.get().then((response) => this.opts.modelos = response);
            }

            this.selectedItem = e;
            this.produtoOpened = true;
          },
        },
        {
          name: "Editar Base de Análise",
          cb: (e) => {
            this.selectedItem = e;
            this.baseOpened = true;
          },
        },
        {
          name: "Editar Valores do Item",
          cb: (e) => {
            this.selectedItem = e;
            this.itemOpened = true;
          },
        },
      ],
    };
  },
};
</script>
